import React, {Component} from "react";

import {
    Badge,
    Button,
    Card,
    CardBody,
    Col,
    Container, FormInput, InputGroup, InputGroupAddon, InputGroupText, ListGroup, ListGroupItem,
    Row
} from "shards-react";
import {Accordion, Icon, Label, Menu, Table} from 'semantic-ui-react'
import "../../App.css"
import { Statistic} from "semantic-ui-react";
import {connect} from "react-redux";
import {global} from "../../config/config";





class ReportPage extends Component{
    constructor(props) {
        super(props);
        this.state = {
            open:false,
            reports: null,
        }

        this.getReports = this.getReports.bind(this)
    }

    componentDidMount() {
        const requestOptions = {
            method: "GET",
            headers: {"Content-Type": "application/json","Authorization": "Bearer "+localStorage.getItem("token")},
        };

        fetch(`${global.API_MAIN}/reports`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                this.setState({reports: data.data})
            })
            .catch(function (err) {
                return err;
            })
            .then((err) => { });
    }

    getReports() {
        this.setState({reports : null})
        const requestOptions = {
            method: "GET",
            headers: {"Content-Type": "application/json","Authorization": "Bearer "+localStorage.getItem("token")},
        };

        fetch(`${global.API_MAIN}/reports`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                this.setState({reports: data.data})
            })
            .catch(function (err) {
                return err;
            })
            .then((err) => { });
    }

    render() {
        // console.clear()
        let REPORTS = []
        if (this.state.reports != null) {
            REPORTS = this.state.reports.map((a) => {
                let time = (new Date(a.createdAt).toLocaleString()).split(",")
                return <Table.Row style={{fontSize:"14px"}}>
                    <Table.Cell>{a.requestID}</Table.Cell>
                    <Table.Cell>{a.ip}</Table.Cell>
                    <Table.Cell><Badge>{a.statusCode}</Badge></Table.Cell>

                    <Table.Cell>{a.route}</Table.Cell>
                    {/*<Table.Cell>{a.userID}</Table.Cell>*/}
                    {/*<Table.Cell>{a.companyID}</Table.Cell>*/}

                    <Table.Cell>{a.description}</Table.Cell>
                    {/*<Table.Cell>{a.paragraph}</Table.Cell>*/}
                    <Table.Cell>{time[1] + " " + time[0]}</Table.Cell>
                    {/*<Table.Cell>{a.time + " " + a.date}</Table.Cell>*/}
                </Table.Row>
            })
        }

        return(
            <div style={{paddingTop:"80px"}}>
                <Container>
                    <Row>
                        <Col style={{ textAlign: "center" }} >
                            <Statistic color='grey' size='mini'>
                                <Statistic.Value>Репорты</Statistic.Value>
                            </Statistic>
                            <br/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col
                            xs={{size: 12, order: 0, offset: 0}}
                            sm={{size: 12, order: 0, offset: 0}}
                            md={{size: 12, order: 0, offset: 0}}
                            lg={{size: 12, order: 0, offset: 0}}
                            xl={{size: 12, order: 0, offset: 0}}
                            style={{textAlign:"left"}}
                        >
                            <Row>
                                <Col >
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    {/*<InputGroup className="mb-2">*/}
                                                    {/*    <InputGroupAddon type="prepend">*/}
                                                    {/*        <InputGroupText style={{color:"black"}}>ErrorID</InputGroupText>*/}
                                                    {/*    </InputGroupAddon>*/}
                                                    {/*    <FormInput placeholder="Начните вводить..." />*/}
                                                    {/*</InputGroup>*/}
                                                </Col>
                                                <Col style={{textAlign:"right"}}>
                                                    <Button theme="secondary" onClick={this.getReports}>Обновить</Button>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row>
                                                <Col>

                                                    <Table  compact>
                                                        <Table.Header>
                                                            <Table.Row>
                                                                <Table.HeaderCell>ErrorID</Table.HeaderCell>
                                                                <Table.HeaderCell>IP</Table.HeaderCell>
                                                                <Table.HeaderCell>Code</Table.HeaderCell>
                                                                <Table.HeaderCell>Route</Table.HeaderCell>
                                                                {/*<Table.HeaderCell>UserID</Table.HeaderCell>*/}
                                                                {/*<Table.HeaderCell>CompanyID</Table.HeaderCell>*/}
                                                                <Table.HeaderCell>Description</Table.HeaderCell>
                                                                {/*<Table.HeaderCell>Paragraph</Table.HeaderCell>*/}
                                                                <Table.HeaderCell>Time</Table.HeaderCell>
                                                            </Table.Row>
                                                        </Table.Header>

                                                        <Table.Body>
                                                            {REPORTS}

                                                        </Table.Body>

                                                        {/*<Table.Footer>*/}
                                                        {/*    <Table.Row>*/}
                                                        {/*        <Table.HeaderCell colSpan='10'>*/}
                                                        {/*            <Menu floated='right' pagination>*/}
                                                        {/*                <Menu.Item as='a' icon>*/}
                                                        {/*                    <Icon name='chevron left' />*/}
                                                        {/*                </Menu.Item>*/}
                                                        {/*                <Menu.Item as='a'>1</Menu.Item>*/}
                                                        {/*                <Menu.Item as='a'>2</Menu.Item>*/}
                                                        {/*                <Menu.Item as='a'>3</Menu.Item>*/}
                                                        {/*                <Menu.Item as='a'>4</Menu.Item>*/}
                                                        {/*                <Menu.Item as='a' icon>*/}
                                                        {/*                    <Icon name='chevron right' />*/}
                                                        {/*                </Menu.Item>*/}
                                                        {/*            </Menu>*/}
                                                        {/*        </Table.HeaderCell>*/}
                                                        {/*    </Table.Row>*/}
                                                        {/*</Table.Footer>*/}
                                                    </Table>
                                                </Col>

                                            </Row>
                                            <br/>


                                            <br/>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </Container>
            </div>
        )
    }
}

function mapStateToProps(state){
    return {
        auth: state.auth,
        user: state.user,
    }
}

export default connect(mapStateToProps, null)(ReportPage)