import React from "react";
import {connect} from "react-redux";
import {Statistic, Container,} from "semantic-ui-react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "shards-react";


import "../../App.css";
import {global} from "../../config/config";
import {auth, signup} from "../../store/actions/auth";
import Login from "./Login";
import Signup from "./Signup";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoginMode: true,
      version: "",
    };

    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChangeUsername = this.handleChangeUsername.bind(this);
    this.handleChangeFirstname = this.handleChangeFirstname.bind(this);

    this.btnLogin = this.btnLogin.bind(this);
    this.btnSignUp = this.btnSignUp.bind(this);
    this.handleBtnRegister = this.handleBtnRegister.bind(this);
    this.handleBtnLogin = this.handleBtnLogin.bind(this);
  }

  handleChangeFirstname(event) {
    this.setState({firstname: event.target.value})
  }

  handleChangePassword(event) {
    this.setState({password: event.target.value})
  }

  handleChangeUsername(event) {
    this.setState({username: event.target.value})
  }

  handleBtnRegister() {
    this.setState({isLoginMode: false})
  }

  handleBtnLogin(event) {
    this.setState({isLoginMode: true})
  }

  btnLogin() {
    this.props.Auth(this.state.username,this.state.password)
  }

  btnSignUp() {
    this.props.SignUP(this.state.firstname,this.state.username,this.state.password)
  }

  componentDidMount() {
    const requestOptions = {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    };

    fetch(`${global.API_MAIN}/ver`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.setState({version: data.version})
      })
      .catch(function (err) {
        return err;
      })
      .then((err) => { });
  }


  render() {
    //console.clear()
    const {
      isLoginMode,
    } = this.state;

    return (
      <div className="login-page" style={{paddingTop:"80px"}}>
        <Container className="dr-example-container">
          <Row>
            <Col
              xs={{ size: 12, offset: 0, order: 0 }}
              sm={{ size: 12, offset: 0, order: 0 }}
              md={{ size: 6, offset: 3, order: 3 }}
              lg={{ size: 4, offset: 4, order: 4 }}
              xl={{ size: 4, offset: 4, order: 4 }}
              style={{ textAlign: "center" }}
            >
              <Card>
                <CardHeader>
                  <Statistic color="black" size="mini">
                    <Statistic.Value>{global.PROJECT_NAME}</Statistic.Value>
                  </Statistic>
                </CardHeader>
                <CardBody>
                  {isLoginMode ? (<Login/>):(<Signup/>)}
                </CardBody>
                <CardFooter>
                  <span>Текущая версия: <span style={{fontWeight:"700"}}>{this.state.version}</span></span>
                </CardFooter>
              </Card>
              <br />
              <br />
            </Col>
          </Row>
        </Container>
        <br />
      </div>
    );
  }
}

function mapStateToProps(state){
  return {
    authStatus: state.auth.status,
    signupStatus: state.signup.status
  }
}

function mapDispatchToProps(dispatch){
  return {
    Auth: (username,password) => dispatch(auth(username,password)),
    SignUP: (firstname,username,password) => dispatch(signup(firstname,username,password)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(LoginPage)
