export const global = {
  PROJECT_NAME : "Vistalt24 Doc",
  VERSION : "v0.505",
  LAST_UPDATE:"20.07.2022",
  API_MAIN: "https://vistalt24.de",
}

export const stringsLogin = {
  SIGNUP_TITLE : "Регистрация",
  SIGNIN_TITLE : "Авторизация",
  LABEL_FIRSTNAME : "Имя",
  LABEL_USERNAME : "Логин",
  LABEL_PASSWORD : "Пароль",
  LABEL_EMAIL: 'Email',
  PLACEHOLDER_FIRSTNAME : "Введите имя",
  PLACEHOLDER_USERNAME : "Введите логин",
  PLACEHOLDER_PASSWORD : "Введите пароль",
  PLACEHOLDER_EMAIL : "Введите email",
  TEXT_FOOTER_SIGNUP : "Нет аккаунта?",
  TEXT_FOOTER_LOGIN : "Есть аккаунт?",
  BTN_TEXT_LOGIN : "Войти",
  BTN_TEXT_SIGNUP : "Создать",

  MSG_STATUS_OK : "Успех! Пользователь создан.",              // {status:"ok"}
  MSG_STATUS_ERROR : "Ошибка! Проверьте данные.",             // {status:"error"}
  MSG_STATUS_FOUND : "Ошибка! Пользователь существует.",      // {status:"found"}
  MSG_STATUS_BLOCKED : "Учетная запись заблокирована.",       // {status:"blocked"}
  MSG_STATUS_DBERROR : "Ошибка! Повторите попытку позже.",    // {status:"db-error"}

  MSG_AUTH_STATUS_VALID : "Успешный вход",                        // {status:"ok"}
  MSG_AUTH_STATUS_INVALID : "Ошибка! Проверьте данные.",          // {status:"error"}
  MSG_AUTH_STATUS_EMPTY : "Ошибка! Пользователя не существует.",  // {status:"found"}
}



