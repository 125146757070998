import {
  AUTH_BLOCKED,
  AUTH_DBERROR,
  AUTH_EMPTY,
  AUTH_ERROR,
  AUTH_INVALID,
  AUTH_LOGOUT,
  AUTH_VALID,
  AUTH_VALID_2,
  SIGNUP_DBERROR,
  SIGNUP_ERROR,
  SIGNUP_FOUND,
  SIGNUP_OK
} from "./actionTypes";
import {global} from "../../config/config";
import {history} from "../../_utils/history";

export function auth(email, password) {
    return async dispatch => {

        const authData = {
            email,
            password,
        }
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(authData),
        };

        fetch(`${global.API_MAIN}/web/auth`, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            switch (data.status){
                case "success":
                localStorage.setItem('token', data.data.access)
                dispatch({type: AUTH_VALID_2, token: localStorage.getItem("token") ,status: data.status, ...data.data})
                history.push("/")
                break
              case "invalid":
                dispatch({type: AUTH_INVALID, status:data.status})
                break
              case "empty":
                dispatch({type: AUTH_EMPTY, status:data.status})
                break
              case "blocked":
                dispatch({type: AUTH_BLOCKED, status:data.status})
                break
              case "error":
                dispatch({type: AUTH_ERROR, status:data.status})
                break
              case "db-error":
                dispatch({type: AUTH_DBERROR, status:data.status})
                break
              default:
                dispatch({type: AUTH_ERROR, status:data.status})
                break
            }

          })
          .catch(function (err) {
              return err;
          })
          .then((err) => {
              if (
                err === "TypeError: NetworkError when attempting to fetch resource."
              ) {
                dispatch({type:SIGNUP_DBERROR})
              }
          });
    }
}

export function signup(firstname,username,password,email){
    return async dispatch => {
        const authData = {
            firstname,
            username,
            password,
            email,
            ctx:"create"
        }
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json", "ctx":"create"},
            body: JSON.stringify(authData),
        };

        fetch(`${global.API_MAIN}/auth/signup`, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            switch (data.status) {
              case "success":
                dispatch({type:SIGNUP_OK})
                break
              case "found":
                dispatch({type:SIGNUP_FOUND})
                break
              default:
                dispatch({type:SIGNUP_ERROR})
                break
            }
          })
          .catch(function (err) {
              return err;
          })
          .then((err) => {
              if (
                err === "TypeError: NetworkError when attempting to fetch resource."
              ) {
                  dispatch({type:SIGNUP_DBERROR})
              }
          });

    }


}

export function autoLogin(){
  return async dispatch => {
    const token = localStorage.getItem("token")
    if (token == null) {
      dispatch(logout())
      return
    }
    const reqOptions = {
      method: "GET",
      headers: {"Content-Type": "application/json", Authorization: `Bearer ${token}`},
    };

    fetch(`${global.API_MAIN}/auth/signin`, reqOptions)
      .then((response) => response.json())
      .then((data) => {
        switch (data.status) {
          case "success":
            dispatch({type: AUTH_VALID, token: localStorage.getItem("token"),status: data.status, ...data})
              history.push("/");
            break
          case "invalid":
            logout()
            dispatch={type: AUTH_INVALID, status: data.status}
            break
          case "blocked":
            logout()
            dispatch={type: AUTH_BLOCKED, status:data.status}
            break
          case "empty":
            logout()
            dispatch={type: AUTH_EMPTY, status: data.status}
            break
          case "error":
            logout()
            dispatch={type: AUTH_ERROR, status: data.status}
            break
          case "db-error":
            logout()
            dispatch={type: AUTH_DBERROR, status: data.status}
            break
          default:
            logout()
            dispatch={type: AUTH_ERROR, status: data.status}
            break
        }
      })
      .catch(function (err) {
        return err;
      })
      .then((err) => {
        if (
          err === "TypeError: NetworkError when attempting to fetch resource."
        ) {
          dispatch={type:SIGNUP_DBERROR}
        }
      });
  }

}

export function logout(){
  localStorage.removeItem("token");
  sessionStorage.removeItem("selectedObjects");
  history.push("/login")
  return {
    type:AUTH_LOGOUT
  }
}


