import React from 'react';

import {Dropdown, Icon} from 'semantic-ui-react'
import SettingsModals from "./SettingsModal";




function SettingsModal(props) {
  return (
    <>
      <Dropdown direction="left" icon="bars">
        <Dropdown.Menu>
          {!props.data.status ? (<>
            <Dropdown.Item onClick={()=>props.handle(props.data,props.guid)}><Icon name="check circle" color="green"/>Выполнено</Dropdown.Item>
          </>):(<>
            <Dropdown.Item onClick={()=>props.handle(props.data,props.guid)}><Icon loading name='spinner' color="orange"/>В ожидании</Dropdown.Item>
          </>)}

          <Dropdown.Item disabled><Icon link name='question circle'/>Информация</Dropdown.Item>
          {/*<Dropdown.Item><SettingsModals data={props.data}/></Dropdown.Item>  /!*Настройки*!/*/}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default SettingsModal;