import React, {Component} from "react";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "shards-react";
import {Accordion, Icon, Segment} from 'semantic-ui-react'
import "../../App.css"
import { Statistic} from "semantic-ui-react";
import {connect} from "react-redux";
import {global} from "../../config/config";
import AddNewModal from "./components/NewModal"
import SettingsModal from "./components/SettingsDropdown";





class HomePage extends Component{
  constructor(props) {
    super(props);
    this.state = {
      open:false,
      version: "",
      web:null,
      client:null,
      webCounter:0,
      webAllCounter:0,
      clientAllCounter:0,
      clientCounter:0,
    }

    // this.handleChangeStatus = this.handleChangeStatus.bind(this)

  }

  componentDidMount() {
    const requestOptions = {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    };

    fetch(`${global.API_MAIN}/ver`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.setState({version: data.version})
      })
      .catch(function (err) {
        return err;
      })
      .then((err) => { });

    fetch(`${global.API_MAIN}/internaldoc/all`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.setState({web: data.webArr, client:data.clientArr})
        let webC = 0
        if (data.webArr != null) {
          for (let i=0;i < data.webArr.length;i++){
            if (data.webArr[i].status === false) {
              webC++
            }
          }
          this.setState({webCounter:webC,webAllCounter:data.webArr.length})
        }

        if (data.clientArr != null) {
          let clientC = 0
          for (let i=0;i < data.clientArr.length;i++){
            if (data.clientArr[i].status === false) {
              clientC++
            }
          }
          this.setState({clientCounter:clientC,clientAllCounter:data.clientArr.length})
        }
      })
      .catch(function (err) {
        return err;
      })
      .then((err) => { });
  }



  render() {
    // console.clear()
    let WEB
    let CLIENT

    function handleChangeStatus(obj,guid) {
      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
            guid: guid,
            uid: obj.uid,
            title: obj.title,
            status: !obj.status
          }
        ),
      };

      fetch(`${global.API_MAIN}/internaldoc/new`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
            // this.setState({ responses2: data.status })
          }
        )
    }

    if (this.state.web !== null) {
      WEB = this.state.web.map((a) => {
        return <Segment key={a.uid} style={{fontWeight:"500", cursor:"pointer"}} onClick={()=>{console.log("test")}}>
          <Row>
            <Col
              xs={{size: 8, order: 0, offset: 0}}
              sm={{size: 8, order: 0, offset: 0}}
              md={{size: 8, order: 0, offset: 0}}
              lg={{size: 8, order: 0, offset: 0}}
              xl={{size: 8, order: 0, offset: 0}}
              style={{textAlign:"left"}}>
              {a.uid.length === 1 ? (<>
                <span style={{fontWeight:"700",color:"black"}}>
                  РАЗДЕЛ {a.uid}. {a.title}
                </span>
              </>):(<>
                <span><span style={{fontWeight:"700"}}>
                  {!a.statusBack ? (<>
                    <Icon name="check circle" color="green"/>
                  </>):(<>
                    <Icon name='minus circle' color="red"/>
                  </>)}
                  {a.uid}</span> {a.title}
                </span>
              </>)}

            </Col>
            <Col
              xs={{size: 3, order: 0, offset: 0}}
              sm={{size: 3, order: 0, offset: 0}}
              md={{size: 3, order: 0, offset: 0}}
              lg={{size: 3, order: 0, offset: 0}}
              xl={{size: 3, order: 0, offset: 0}}
              style={{textAlign:"right"}}>
              {a.status ? (<>
                <span style={{color:"green"}}>Выполнен</span> <Icon name="check circle" color="green"/>
              </>):(<>
                <span style={{color:"orange"}}>В ожидании</span> <Icon loading name='spinner' color="orange"/>
              </>)}
            </Col>
            <Col
              xs={{size: 1, order: 0, offset: 0}}
              sm={{size: 1, order: 0, offset: 0}}
              md={{size: 1, order: 0, offset: 0}}
              lg={{size: 1, order: 0, offset: 0}}
              xl={{size: 1, order: 0, offset: 0}}
              style={{textAlign:"right"}}>
              <SettingsModal data={a} handle={()=>handleChangeStatus(a,"web")}  />
            </Col>
          </Row>
        </Segment>
      })
    }

    if (this.state.client !== null) {
      CLIENT = this.state.client.map((a,i) => {
        return <Segment key={a.uid} i={i} style={{fontWeight:"500", cursor:"pointer"}} onClick={()=>{console.log(i,"test")}}>
          <Row>
            <Col style={{textAlign:"left"}}>
              {a.uid.length === 1 ? (<>
                <span style={{fontWeight:"700",color:"black"}}>
                  РАЗДЕЛ {a.uid}. {a.title}
                </span>
              </>):(<>
                <span><span style={{fontWeight:"700"}}>
                  {!a.statusBack ? (<>
                    <Icon name="check circle" color="green"/>
                    </>):(<>
                    <Icon name='minus circle' color="red"/>
                    </>)}
                  {a.uid}</span> {a.title}
                </span>
              </>)}

            </Col>
            <Col style={{textAlign:"right"}}>
              {this.state.client[i].status ? (<>
                <span style={{color:"green"}}>Выполнен</span> <Icon name="check circle" color="green"/>
              </>):(<>
                <span style={{color:"orange"}}>В ожидании</span> <Icon loading name='spinner' color="orange"/>
              </>)}
            </Col>
            <Col
              xs={{size: 1, order: 0, offset: 0}}
              sm={{size: 1, order: 0, offset: 0}}
              md={{size: 1, order: 0, offset: 0}}
              lg={{size: 1, order: 0, offset: 0}}
              xl={{size: 1, order: 0, offset: 0}}
              style={{textAlign:"right"}}>
              <SettingsModal data={a} handle={()=>handleChangeStatus(a,"web")}  />
            </Col>
          </Row>
        </Segment>
      })
    }


    // if (this.state.web !== "" && this.state.web !== null) {
    //   objWEB = this.state.web[0].doc
    //   WEB = Object.keys(objWEB).sort().map(function(key, index,a) {
    //     return <Segment key={objWEB[key].uid} style={{fontWeight:"500", cursor:"pointer"}} onClick={()=>{console.log("test")}}>
    //       <Row>
    //         <Col style={{textAlign:"left"}}>
    //           {objWEB[key].uid.length === 1 ? (<>
    //             <span style={{fontWeight:"700",color:"black"}}>{objWEB[key].uid} {objWEB[key].title}</span>
    //           </>):(<>
    //             <span>{objWEB[key].uid} {objWEB[key].title}</span>
    //           </>)}
    //
    //         </Col>
    //         <Col style={{textAlign:"right"}}>
    //           {objWEB[key].status ? (<>
    //             <span style={{color:"green"}}>Выполнен</span> <Icon name="check circle" color="green"/>
    //           </>):(<>
    //             <span style={{color:"orange"}}>В ожидании</span> <Icon loading name='spinner' color="orange"/>
    //           </>)}
    //         </Col>
    //         <Col
    //           xs={{size: 1, order: 0, offset: 0}}
    //           sm={{size: 1, order: 0, offset: 0}}
    //           md={{size: 1, order: 0, offset: 0}}
    //           lg={{size: 1, order: 0, offset: 0}}
    //           xl={{size: 1, order: 0, offset: 0}}
    //           style={{textAlign:"right"}}>
    //           <SettingsModal data={objWEB[key]} handle={()=>handleChangeStatus(objWEB[key],"web")}  />
    //         </Col>
    //       </Row>
    //     </Segment>
    //   });
    // }

    // if (this.state.client !== "" && this.state.client !== null ) {
    //   objCLIENT = this.state.client[0].doc
    //   CLIENT = Object.keys(objCLIENT).sort().map(function(key, index,a) {
    //
    //     return <Segment key={objCLIENT[key].uid}  style={{fontWeight:"500", cursor:"pointer"}} onClick={()=>{console.log("test")}}>
    //       <Row>
    //         <Col style={{textAlign:"left"}}>
    //           {objCLIENT[key].uid.length === 1 ? (<>
    //             <span style={{fontWeight:"700",color:"black"}}>{objCLIENT[key].uid} {objCLIENT[key].title}</span>
    //           </>):(<>
    //             <span>{objCLIENT[key].uid} {objCLIENT[key].title}</span>
    //           </>)}
    //         </Col>
    //         <Col style={{textAlign:"right"}}>
    //           {objCLIENT[key].status ? (<>
    //             <span style={{color:"green"}}>Выполнен</span> <Icon name="check circle" color="green"/>
    //           </>):(<>
    //             <span style={{color:"orange"}}>В ожидании</span> <Icon loading name='spinner' color="orange"/>
    //           </>)}
    //         </Col>
    //         <Col
    //           xs={{size: 1, order: 0, offset: 0}}
    //           sm={{size: 1, order: 0, offset: 0}}
    //           md={{size: 1, order: 0, offset: 0}}
    //           lg={{size: 1, order: 0, offset: 0}}
    //           xl={{size: 1, order: 0, offset: 0}}
    //           style={{textAlign:"right"}}>
    //           <SettingsModal data={objCLIENT[key]} handle={()=>handleChangeStatus(objCLIENT[key],"client")} />
    //         </Col>
    //       </Row>
    //     </Segment>
    //   });
    // }

    const WEBPANEL = (
      <div>
        <Segment.Group>
          {WEB}
        </Segment.Group>
      </div>
    )

    const CLIENTPANEL = (
      <div>
        <Segment.Group>
          {CLIENT}
        </Segment.Group>
      </div>
    )

    const rootPanels = [
      { key: 'panel-1', title: `WEB & WORKER APP [ ${this.state.webAllCounter-this.state.webCounter} выполнено / ${this.state.webCounter} ожидает выполнения ]`, content: { content: WEBPANEL } },
      { key: 'panel-2', title: `CLIENT APP [ ${this.state.clientAllCounter-this.state.clientCounter} выполнено / ${this.state.clientCounter} ожидает выполнения ]`, content: { content: CLIENTPANEL } },
    ]

    return(
      <div style={{paddingTop:"80px"}}>
        <Container>
          <Row>
            <Col style={{ textAlign: "center" }} >
              <Statistic color='grey' size='mini'>
                <Statistic.Value>Документация</Statistic.Value>
              </Statistic>
              <br/>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col
              xs={{size: 12, order: 0, offset: 0}}
              sm={{size: 12, order: 0, offset: 0}}
              md={{size: 4, order: 0, offset: 0}}
              lg={{size: 4, order: 0, offset: 0}}
              xl={{size: 3, order: 0, offset: 0}}
            >
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <span style={{fontSize:"18px"}}>Список документов</span>
                    </Col>
                  </Row>
                  <br/>
                  <br/>
                  <Row>
                    <Col>
                      <Button pill theme="secondary" href="https://vistalt24.de/doc/app/web" target="_blank" style={{width:"150px"}}>Web & Worker PDF</Button>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col>
                      <span style={{fontWeight:"500"}}>Версия ядра: <span style={{fontWeight:"700"}}>{this.state.version}</span></span>
                    </Col>
                  </Row>
                </CardBody>
              </Card><br/>
            </Col>

            <Col
              xs={{size: 12, order: 0, offset: 0}}
              sm={{size: 12, order: 0, offset: 0}}
              md={{size: 8, order: 0, offset: 0}}
              lg={{size: 8, order: 0, offset: 0}}
              xl={{size: 9, order: 0, offset: 0}}
              style={{textAlign:"left"}}
            >
              <Row>
                <Col >
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <Icon name="file alternate" size="small" style={{fontSize:"17px"}}/>
                          <Statistic color='black' size='mini'>
                            <Statistic.Value>
                              Список документов
                            </Statistic.Value>
                          </Statistic>
                        </Col>
                        <Col style={{textAlign:"right"}}>
                          <AddNewModal/>
                        </Col>
                      </Row>
                      <br/>

                      <Accordion panels={rootPanels} />
                      <br/>

                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
        </Container>
      </div>
    )
  }
}

function mapStateToProps(state){
  return {
    auth: state.auth,
    user: state.user,
  }
}

export default connect(mapStateToProps, null)(HomePage)
