export const SIGNUP_OK = "SIGNUP_OK"
export const SIGNUP_ERROR = "SIGNUP_ERROR"
export const SIGNUP_FOUND = "SIGNUP_FOUND"
export const SIGNUP_DBERROR = "SIGNUP_DBERROR"

export const AUTH_VALID = "AUTH_VALID"
export const AUTH_VALID_2 = "AUTH_VALID_2"
export const AUTH_INVALID = "AUTH_INVALID"
export const AUTH_EMPTY = "AUTH_EMPTY"
export const AUTH_BLOCKED = "AUTH_BLOCKED"
export const AUTH_ERROR = "AUTH_ERROR"
export const AUTH_DBERROR = "AUTH_DBERROR"
export const AUTH_LOGOUT = "AUTH_LOGOUT"