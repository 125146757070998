import {SIGNUP_DBERROR, SIGNUP_ERROR, SIGNUP_FOUND, SIGNUP_OK,} from "../actions/actionTypes";

const initialState = {
  status: null,
}

export default function signup(state=initialState,actions){
  switch (actions.type){
    case SIGNUP_OK:
      return {
        ...state,
        status: "ok",
      }
    case SIGNUP_FOUND:
      return {
        ...state,
        status: "found",
      }
    case SIGNUP_ERROR:
      return {
        ...state,
        status: "error",
      }
    case SIGNUP_DBERROR:
      return {
        ...state,
        status: "db-error",
      }
    default:
      return state
  }
}