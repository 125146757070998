import {
  AUTH_BLOCKED,
  AUTH_DBERROR,
  AUTH_EMPTY,
  AUTH_ERROR,
  AUTH_INVALID,
  AUTH_LOGOUT,
  AUTH_VALID,
  AUTH_VALID_2
} from "../actions/actionTypes";

const initialState = {
  status : null,
  token: null,
}

export default function auth(state= initialState,actions){
  switch (actions.type){
    case AUTH_VALID:
      return {
        token: actions.token,
        status: actions.status,
      }
    case AUTH_VALID_2:
      return {
        token: actions.token,
        status: actions.status,
      }
    case AUTH_INVALID:
      return {
        status: actions.status,
      }
    case AUTH_EMPTY:
      return {
        status: actions.status,
      }
    case AUTH_BLOCKED:
      return {
        status: actions.status,
      }
    case AUTH_ERROR:
      return {
        status: actions.status,
      }
    case AUTH_DBERROR:
      return {
        status: actions.status,
      }
    case AUTH_LOGOUT:
      return {
        token: null,
        status: null,
      }
    default:
      return state
  }
}