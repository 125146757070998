import React, {Component} from "react";
import {Message, Statistic} from "semantic-ui-react";
import {stringsLogin} from "../../config/config";
import {Button, Col, FormInput, InputGroup, InputGroupAddon, InputGroupText, Row} from "shards-react";
import {connect} from "react-redux";
import {signup} from "../../store/actions/auth";
import {validateForm} from "../../_utils/validate";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      username: "",
      password: "",
      email: "",
      server_msg: false,
      colorMSG: false,
      status: false,
      isBtnDisable: false,
      NotValidMSG: false,
      showBtn:true,

    }

    this.btnSignUp = this.btnSignUp.bind(this)

    this.handleChangeFirstname = this.handleChangeFirstname.bind(this)
    this.handleChangeUsername = this.handleChangeUsername.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.handleChangeEmail = this.handleChangeEmail.bind(this)

  }

  btnSignUp() {
    this.setState({status: true, isBtnDisable:true}) //блокировка кнопки
    if (validateForm(this.state.username,this.state.password,this.state.firstname, this.state.email)){
      this.setState({NotValidMSG:false})
      this.props.signUp(this.state.firstname, this.state.username, this.state.password, this.state.email)
    } else { this.setState({NotValidMSG:true}) }
  }


  handleChangeFirstname(e) {
    this.setState({firstname: e.target.value, status: false,NotValidMSG:false, isBtnDisable:false})
  }

  handleChangeUsername(e) {
    this.setState({username: e.target.value, status: false, NotValidMSG:false, isBtnDisable:false})
  }

  handleChangePassword(e) {
    this.setState({password: e.target.value, status: false, NotValidMSG:false, isBtnDisable:false})
  }

  handleChangeEmail(e) {
    this.setState({email: e.target.value, status: false, NotValidMSG:false, isBtnDisable:false})
  }

  render() {
    let {server_msg, colorMSG, status,showBtn,NotValidMSG} = this.state
    if (status) {
      server_msg = false
      switch (this.props.status) {
        case "ok":
          server_msg = stringsLogin.MSG_STATUS_OK
          colorMSG = "green"
          showBtn = false
          break
        case "found":
          server_msg = stringsLogin.MSG_STATUS_FOUND
          colorMSG = "red"
          break
        case "error":
          server_msg = stringsLogin.MSG_STATUS_ERROR
          colorMSG = "red"
          break
        case "db-error":
          server_msg = stringsLogin.MSG_STATUS_DBERROR
          colorMSG = "red"
          break
        default:
          server_msg = false
          colorMSG = "red"
          break
      }
    }

    if (NotValidMSG){
      server_msg = stringsLogin.MSG_AUTH_STATUS_INVALID
    }

      return (
        <div>
          <Row>
            <Col className="mb-2">
              <Statistic>
                <Statistic.Label color="black">
                  {stringsLogin.SIGNUP_TITLE}
                </Statistic.Label>
              </Statistic>
              <br/><br/>
              <InputGroup className="mb-2">
                <InputGroupAddon type="prepend">
                  <InputGroupText style={{width: "71px"}}>
                    {stringsLogin.LABEL_FIRSTNAME}
                  </InputGroupText>
                </InputGroupAddon>
                <FormInput
                  valid={this.state.validFirstName}
                  invalid={this.state.invalidFirstName}
                  placeholder={stringsLogin.PLACEHOLDER_FIRSTNAME}
                  value={this.state.firstname}
                  onChange={this.handleChangeFirstname}
                />
              </InputGroup>

              <InputGroup className="mb-2">
                <InputGroupAddon type="prepend">
                  <InputGroupText style={{width: "71px"}}>
                    {stringsLogin.LABEL_USERNAME}
                  </InputGroupText>
                </InputGroupAddon>
                <FormInput
                  placeholder={stringsLogin.PLACEHOLDER_USERNAME}
                  value={this.state.username}
                  onChange={this.handleChangeUsername}
                />
              </InputGroup>

              <InputGroup className="mb-2">
                <InputGroupAddon type="prepend">
                  <InputGroupText style={{width: "71px"}}>
                    {stringsLogin.LABEL_EMAIL}
                  </InputGroupText>
                </InputGroupAddon>
                <FormInput
                  placeholder={stringsLogin.PLACEHOLDER_EMAIL}
                  value={this.state.email}
                  onChange={this.handleChangeEmail}
                />
              </InputGroup>

              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText style={{width: "71px"}}>
                    {stringsLogin.LABEL_PASSWORD}
                  </InputGroupText>
                </InputGroupAddon>
                <FormInput
                  placeholder={stringsLogin.PLACEHOLDER_PASSWORD}
                  type="password"
                  required
                  id="password"
                  value={this.state.password}
                  onChange={this.handleChangePassword}
                />
              </InputGroup>
            </Col>
          </Row>


          <Row>
            <Col className="mb-2" style={{textAlign: "center"}}>
              {server_msg ? (
                <>
                  <Message color={colorMSG} size="tiny">{server_msg}</Message>
                </>
              ) : (<></>)}
            </Col>
          </Row>

          {showBtn ? (<>
            <Row>
              <Col className="mb-2" style={{textAlign: "center"}}>
                <Button disabled={this.state.isBtnDisable} className="mb-2" theme="secondary" onClick={this.btnSignUp}>
                  {stringsLogin.BTN_TEXT_SIGNUP}
                </Button>
              </Col>
            </Row>
          </>):(<></>)}
        </div>
      )
    }
}

function mapStateToProps(state){
  return {
    status: state.signup.status
  }
}

function mapDispatchToProps(dispatch){
  return {
    signUp: (firstname,username,password,email) => dispatch(signup(firstname,username,password,email))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Signup)