import React from 'react';
import ReactDOM from 'react-dom';

import reportWebVitals from './reportWebVitals';
import {createStore, compose, applyMiddleware} from "redux"
import {Provider} from "react-redux"
import reduxThunk from "redux-thunk"
import rootReducer from "./store/reducers/rootReducer";
import {BrowserRouter} from "react-router-dom";
import 'semantic-ui-css/semantic.min.css'
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css"
import App from './App';
import './index.css';

const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        }) : compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(
    reduxThunk,
)))

const app = (
    <BrowserRouter>
        <Provider store={store}>
            <App/>
        </Provider>
    </BrowserRouter>
)

ReactDOM.render(
  app,
  document.getElementById('root')
);

reportWebVitals();
