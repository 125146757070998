import React, {Component} from "react";
import {Message, Statistic} from "semantic-ui-react";
import {stringsLogin} from "../../config/config";
import {Button, Col, FormInput, InputGroup, InputGroupAddon, InputGroupText, Row} from "shards-react";
import {auth} from "../../store/actions/auth";
import {connect} from "react-redux";

class Login extends Component{
  constructor(props) {
    super(props);
    this.state = {
      email: "tester@gmail.com",
      password: "",
      server_msg: false,
      colorMSG: false,
      status: false,
      isBtnDisable: false,
      NotValidMSG: false,
      showBtn:true,
    }

    this.btnLogin = this.btnLogin.bind(this)

    this.changeUsername = this.changeUsername.bind(this)
    this.changePassword = this.changePassword.bind(this)
  }

  changeUsername(e) {
    this.setState({email: e.target.value, status: false, NotValidMSG:false, isBtnDisable:false})
  }

  changePassword(e) {
    this.setState({password: e.target.value, status: false, NotValidMSG:false, isBtnDisable:false})
  }

  btnLogin() {
    this.setState({status: true, isBtnDisable:true})
    this.props.Auth(this.state.email,this.state.password)
  }

  render() {
    let {server_msg, colorMSG, status,showBtn,NotValidMSG} = this.state
    if (status) {
      server_msg = false
      switch (this.props.status) {
        case "ok":
          server_msg = stringsLogin.MSG_AUTH_STATUS_VALID
          colorMSG = "green"
          showBtn = false
          break
        case "invalid":
          server_msg = stringsLogin.MSG_AUTH_STATUS_INVALID
          colorMSG = "red"
          break
        case "empty":
          server_msg = stringsLogin.MSG_AUTH_STATUS_EMPTY
          colorMSG = "red"
          break
        case "blocked":
          server_msg = stringsLogin.MSG_STATUS_BLOCKED
          colorMSG = "red"
          break
        case "db-error":
          server_msg = stringsLogin.MSG_STATUS_DBERROR
          colorMSG = "red"
          break
        default:
          server_msg = false
          colorMSG = "red"
          break
      }
    }

    if (NotValidMSG){
      server_msg = stringsLogin.MSG_AUTH_STATUS_INVALID
    }

    return (
      <div>
        <Row>
          <Col className="mb-2">
            <Statistic>
              <Statistic.Label color="black">
                {stringsLogin.SIGNIN_TITLE}
              </Statistic.Label>
            </Statistic>
            <br/><br/><br/>

            <InputGroup className="mb-2">
              <InputGroupAddon type="prepend">
                <InputGroupText style={{ width: "71px" }}>
                  {stringsLogin.LABEL_USERNAME}
                </InputGroupText>
              </InputGroupAddon>
              <FormInput
                placeholder={stringsLogin.PLACEHOLDER_USERNAME}
                value={this.state.email}
                onChange={this.changeUsername}
              />
            </InputGroup>

            <InputGroup>
              <InputGroupAddon type="prepend">
                <InputGroupText style={{ width: "71px" }}>
                  {stringsLogin.LABEL_PASSWORD}
                </InputGroupText>
              </InputGroupAddon>

                <FormInput
                  placeholder={stringsLogin.PLACEHOLDER_PASSWORD}
                  type="password"
                  required
                  id="password"
                  className="form"
                  value={this.state.password}
                  onChange={this.changePassword}
                />
            </InputGroup>
          </Col>
        </Row>

        <Row>
          <Col className="mb-2" style={{textAlign: "center"}}>
            {server_msg ? (
              <>
                <Message color={colorMSG} size="tiny">{server_msg}</Message>
              </>
            ) : (<></>)}
          </Col>
        </Row>

        {showBtn ? (<>
        <Row>
          <Col className="mb-2" style={{ textAlign: "center" }}>
            <Button disabled={this.state.isBtnDisable} className="mb-2" theme="secondary" onClick={this.btnLogin}>
              {stringsLogin.BTN_TEXT_LOGIN}
            </Button>
          </Col>
        </Row>
          </>):(<></>)}
      </div>
    )
  }
}

function mapStateToProps(state){
  return {
    status: state.auth.status
  }
}

function mapDispatchToProps(dispatch){
  return {
    Auth: (username,password) => dispatch(auth(username,password)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Login)