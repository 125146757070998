const validUsernameChars = new RegExp('^[a-zA-Z_0-9а-яА-Я-]*$');
const validFirstnameChars = new RegExp('^[a-zA-Z_а-яА-Я-]*$');
const validPassChars = new RegExp('^[a-zA-Z_0-9!?@#$%&]*$');

export function validateUsername(login) {
  if (login.length < 6) return false;
  if (login.length > 20) return false;
  if (!validUsernameChars.test(login)) return false;
  return true;
}

export function validateFirstName(firstname) {
  if (firstname.length < 2) return false;
  if (firstname.length > 12) return false;
  if (!validFirstnameChars.test(firstname)) return false;
  return true;
}

export function validatePassword(pass) {
  if (pass.length < 6) return false;
  if (pass.length > 20) return false;
  if (!validPassChars.test(pass)) return false;
  return true;
}

export function validateForm(username,password,firstname){

  if (username.length < 6) return false;
  if (username.length > 20) return false;
  if (!validUsernameChars.test(username)) return false;

  if (password.length < 6) return false;
  if (password.length > 20) return false;
  if (!validPassChars.test(password)) return false;

  if (firstname){
    if (firstname.length < 2) return false;
    if (firstname.length > 12) return false;
    if (!validFirstnameChars.test(firstname)) return false;
  }

  return true
}




