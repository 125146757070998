import {
  AUTH_VALID,AUTH_VALID_2,
} from "../actions/actionTypes";

const initialState = {
  status: null,
  email: null,
  firstname: null,
  isActive: null,
  notify: null,
  role: null,
  username: null,
}


export default function user(state= initialState,actions){
  switch (actions.type){
    case AUTH_VALID_2:
      return {
        ...state,
        status: actions.status,
        firstname: actions.name,
        isActive: true,
        username: actions.name,
      }
    case AUTH_VALID:
      return {
        ...state,
        status: actions.status,
        email: actions.user.email,
        firstname: actions.status,
        isActive: true,
        notify: actions.notify,
        role: actions.user.role,
        username: actions.user.name,
      }
    default:
      return state
  }
}