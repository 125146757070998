import {Router, Route, Switch, Redirect, Link} from "react-router-dom";
import {Component} from "react";
import LoginPage from "./Pages/LoginPage/LoginPage";
import HomePage from "./Pages/HomePage/HomePage";
import {history} from "./_utils/history";
import {
  Col,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  Row,
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "shards-react";
import logo from "./logo.jpg"

import {connect} from "react-redux";
import {global} from "./config/config";
import {autoLogin, logout} from "./store/actions/auth";
import { Divider,  Icon, Image} from "semantic-ui-react";
import './App.css';
import ReportPage from "./Pages/ReportPage/ReportPage";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      dropdownUserOpen: false,
      collapseOpen: false,
    }

    this.toggleDropdownUser = this.toggleDropdownUser.bind(this);
    this.toggleNavbar = this.toggleNavbar.bind(this)
    this.toggleCloseMenu = this.toggleCloseMenu.bind(this)

  }

  componentDidMount() {
    this.props.AutoLogin()
  }

  toggleDropdownUser() {
    this.setState({
      ...{
        dropdownUserOpen: !this.state.dropdownUserOpen,
      }
    });

  }

  toggleNavbar() {
    this.setState({
      ...{
        collapseOpen: !this.state.collapseOpen,
      },
    });
  }

  toggleCloseMenu() {
    if (this.state.collapseOpen === true) {
      this.setState({ collapseOpen: false });
    }

  }

  render() {
    // console.clear()
    let routes = (
      <Switch>
        <Route path="/login" component={LoginPage}/>
        <Route path="/profile" component={LoginPage}/>
        <Route path="/report" component={ReportPage}/>
        <Route path="/settings" component={LoginPage}/>
        <Route path="/" exact component={HomePage}/>
        <Redirect to="/"/>
      </Switch>
    )

    if (this.props.auth.status === "success"){
      if (this.props.user.isActive) {
            routes = (
              <Switch>
                <Route path="/login" component={LoginPage}/>
                <Route path="/report" component={ReportPage}/>
                <Route path="/" exact component={HomePage}/>
                <Redirect to="/"/>
              </Switch>
            )
      } else {
        routes = (
          <>{() => this.props.Logout()}</>
        )
      }
    }

    return(
      <div className="App">
        <Router history={history}>
          <div>
            {this.props.auth.status === "success" && (<>
              <Row>
                <Col>
                  <Navbar
                    type="light"
                    expand="md"
                    fixed="top"
                    className="App-header"
                    style={{padding:".38rem 1.5rem"}}
                  >
                    <NavbarBrand href="/">
                      <span style={{ color: "black", maxHeight:"2rem"}}>
                        <Image src={logo} avatar style={{width:"32px", height:"32px"}}/>
                        <span style={{position:"relative",padding:"0 .3rem", fontWeight:"500"}}>
                            {global.PROJECT_NAME}
                        </span>
                      </span>
                    </NavbarBrand>

                    <NavbarToggler
                      className="header-text"
                      onClick={this.toggleNavbar}
                    />

                    <Collapse open={this.state.collapseOpen} navbar>
                      <Nav navbar className="ml-auto" style={{color:"black"}}>
                        {this.props.user.role === "Admin" ? ( //admin
                          <>
                            <NavItem style={{color:"black"}}>
                                <Link
                                  className="header-text nav-link active"
                                  style={{color:"black"}}
                                  to="/adm"
                                  onClick={this.toggleCloseMenu}
                                >
                                  <Icon name="shield alternate"/>
                                  Панель управления
                                </Link>
                            </NavItem>
                          </>
                        ) : (
                          <></>
                        )}

                        <Dropdown
                          open={this.state.dropdownUserOpen}
                          toggle={this.toggleDropdownUser}
                          size="sm"
                          className="mr-2"
                        >
                          <DropdownToggle nav caret style={{color:"black"}}>
                            <Image src={logo} avatar style={{width:"28px", height:"28px", fontWeight:"400"}}/> {this.props.user.username}
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem>
                              <Link className="" style={{color:"black",textDecoration:"none"}} to="/" onClick={this.toggleCloseMenu}>
                                <Icon name="copy outline"/>
                                Документация
                              </Link>
                            </DropdownItem>
                            <DropdownItem>
                              <Link className="" style={{color:"black",textDecoration:"none"}} to="/report" onClick={this.toggleCloseMenu}>
                                <Icon name="fire" color="red"/>
                                Репорты
                              </Link>
                            </DropdownItem>
                            <Divider style={{margin:"0px"}}/>
                            <DropdownItem>
                              <Link className="" to="/login" style={{color:"black",textDecoration:"none"}} onClick={this.props.Logout}>
                                <Icon name="log out"/>
                                Выйти
                              </Link>
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </Nav>
                    </Collapse>
                  </Navbar>
                </Col>
              </Row>
            </>)}
            {routes}
          </div>
        </Router>
      </div>
    )
  }
}

function mapStateToProps(state){
  return {
    auth: state.auth,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch){
  return {
    AutoLogin: (token) => dispatch(autoLogin(token)),
    Logout: () => {
      window.location.reload(true);
      dispatch(logout())
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App)