import React from "react";
import {
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  FormInput,
  Button,
  Row,
  Col,
  ButtonGroup, FormCheckbox, FormSelect,
} from "shards-react";
import { Message } from "semantic-ui-react";
// import {global} from "../../../config/strings";
import {connect} from "react-redux";

class SettingsView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      active:false,

      showErrMsg: false,
      showErrMsgColor: false,

      serverMsg: false,
      serverMsgColor: false,
      checked: false
    };


    this.handleChangeUid = this.handleChangeUid.bind(this)
    this.handleChangeTitle = this.handleChangeTitle.bind(this);
    this.handleChangeCity = this.handleChangeCity.bind(this);
    this.handleChangeGPS = this.handleChangeGPS.bind(this);
    this.handleChangeIsActive = this.handleChangeIsActive.bind(this);
    this.handleChangeUsers = this.handleChangeUsers.bind(this);
    this.handleBtnApply = this.handleBtnApply.bind(this)

    this.handleChange = this.handleChange.bind(this);

  }

  handleChange() {
    this.setState({
      checked: !this.state.checked
    });
  }

  handleChangeUid(e) {
    this.setState({ uid: e.target.value });
  }

  handleChangeTitle(e) {
    this.setState({ title: e.target.value });
  }

  handleChangeCity(e) {
    this.setState({ city: e.target.value });
  }

  handleChangeGPS(e) {
    this.setState({ gps: e.target.value });
  }

  handleChangeIsActive(e) {
    this.setState({ active: e.target.value });
  }

  handleChangeUsers(e) {
    this.setState({ users: e.target.value });
  }

  handleBtnApply(){
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", "Authorization": "Bearer "+ this.props.token },
      body: JSON.stringify({
        uid: this.state.uid,
        title: this.state.title,
        city: this.state.city,
        gps: this.state.gps,
        ctx: "_deviceChange",
      }),
    };

    fetch(`${global.API_MAIN}/api1/device/settings`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        if (data.status) {
          this.setState({ serverMsg: data.status });
        }
      })
      .catch(function (err) {
        //console.log("Fetch Error :-S", err);
        return err;
      })
      .then((err) => {
        console.log(err)

        if (
          err === "TypeError: NetworkError when attempting to fetch resource."
        ) {
          this.setState({ serverMsg: "db-error" });
        }
      });
  }

  render() {
    // console.log(this.props.data,"11")
    return (
      <>
        {this.state.serverMsg ? (
          <>
            <Message>
              {this.state.serverMsg === "ok" ? (<>
                <p style={{color:"green"}}>Данные успешно изменены.</p>
              </>):(<></>)}
              {this.state.serverMsg === "error" ? (<>
                <p style={{color:"orange"}}>Ошибка! Проверьте данные.</p>
              </>):(<></>)}
              {this.state.serverMsg === "empty" ? (<>
                <p style={{color:"orange"}}>Ошибка! Устройства уже не существует.</p>
              </>):(<></>)}
              {this.state.serverMsg === "db-error" ? (<>
                <p style={{color:"red"}}>Сервер не доступен. Свяжитесь с администратором.</p>
              </>):(<></>)}
            </Message>
          </>
        ) : (
          <></>
        )}

        <Row>
          <Col
            lg="2"
          >
            <b>Пределы</b><br/>

          </Col>
          <Col>
            <FormCheckbox
              toggle
              small
              checked={this.state.checked}
              onChange={this.handleChange}>

            </FormCheckbox>
          </Col>
        </Row>

        <Row>
          <Col>
            Когда значение валюты достигнет указанного предела, то вам будет отправлено уведомление во все подключенные
            каналы связи.
          </Col>
        </Row>
        <br/>


        <Row>
          <Col>
            Текущий курс
          </Col>
          <Col>
            <FormSelect style={{width:"130px"}}>
              <option value="first">больше/равно</option>
              <option value="second">меньше/равно</option>
              <option value="third">равно</option>
              <option value="third">Рост на %</option>
              <option value="third">Спад на %</option>
              <option value="third">Рост на $</option>
              <option value="third">Спад на $</option>
            </FormSelect>
          </Col>

          <Col>
            <FormInput
              className="mb-2"
              placeholder="Введите значение"
              // valid={validDevname}
              value={this.state.title}
              onChange={this.handleChangeTitle}
            />
          </Col>
        </Row>




        <InputGroup className="mb-2">
          <InputGroupAddon type="prepend">
            <InputGroupText style={{width:"120px"}}>Адрес</InputGroupText>
          </InputGroupAddon>
          <FormInput
            placeholder="Введите новый адрес"
            // valid={validAddress}
            value={this.state.city}
            onChange={this.handleChangeCity}
          />
        </InputGroup>

        <InputGroup className="mb-2">
          <InputGroupAddon type="prepend">
            <InputGroupText style={{width:"120px"}}>GPS</InputGroupText>
          </InputGroupAddon>
          <FormInput
            placeholder="Введите новый порт"
            // valid={validPort}
            value={this.state.gps}
            onChange={this.handleChangeGPS}
          />
        </InputGroup>




        <br />
        <br />
        <Row className="mb-2">
          <Col style={{ textAlign: "center" }}>
            <ButtonGroup size="sm">
              <Button onClick={this.props.closeModal} theme="secondary">
                Закрыть
              </Button>

              <Button theme="secondary" onClick={this.handleBtnApply}>Применить</Button>
            </ButtonGroup>
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state){
  return {
    auth: state.auth,
    user: state.user,
  }
}

// function mapDispatchToProps(dispatch){
//   return {
//     Auth: (username,password) => dispatch(auth(username,password)),
//   }
// }

export default connect(mapStateToProps,null)(SettingsView)
