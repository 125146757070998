import React, {Component} from "react";
import {
  Button,
  Card,
  CardBody,
  Col, FormInput, FormSelect, FormTextarea, InputGroup, InputGroupAddon, InputGroupText,
  Row
} from "shards-react";
import {Icon, Message, Segment,} from "semantic-ui-react"
import {connect} from "react-redux";
import {global} from "../../../config/config";


class UserPairView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      guid:"web",
      uid:"",
      title:"",
      response:false,
      responseText:"",
    };

    this.toggle = this.toggle.bind(this);
    this.handleBtnApply = this.handleBtnApply.bind(this)
    this.handleChangeUID = this.handleChangeUID.bind(this)
    this.handleChangeTITLE = this.handleChangeTITLE.bind(this)
    this.handleChangeGUID = this.handleChangeGUID.bind(this)
  }

  toggle() {
    this.setState({
      open: !this.state.open,

    });
  }


  handleBtnApply(){
    //fetch
    const requestOptions = {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
          guid: this.state.guid,
          uid: this.state.uid,
          title: this.state.title,
      }
      ),
    };



    fetch(`${global.API_MAIN}/internaldoc/new`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
         this.setState({ response: data.status })
        }
      )
  }

  handleChangeUID(e){
    this.setState({uid:e.target.value, response:""})
  }

  handleChangeGUID(e){
    this.setState({guid:e.target.value, response:""})
  }

  handleChangeTITLE(e){
    this.setState({ title: e.target.value, response:""})
  }


  componentDidMount() {
  }

  render() {

    let {responseHeader} = this.state
    let color
    switch (this.state.response){
      case "success":
        responseHeader= "Успешно"
        color="green"
        break
      case "error":
        responseHeader= "Ошибка"
        color="red"
        break
      default:
        break
    }

    return (
      <div style={{textAlign:"center"}} >
        {this.state.response ?  (<>
            <Message color={color}>
              {responseHeader}
            </Message>
          </>):(<></>)}


        <Row>
          <Col
            xs={{ size: 12, offset: 0, order: 0 }}
            sm={{ size: 12, offset: 0, order: 0 }}
            md={{ size: 12, offset: 0, order: 0 }}
            lg={{ size: 12, offset: 0, order: 0 }}
            xl={{ size: 12, offset: 0, order: 0 }}
          >

            <InputGroup className="mb-2">
              <InputGroupAddon type="prepend">
                <InputGroupText style={{width:"80px", color:"black"}}>Документ</InputGroupText>
              </InputGroupAddon>
              <FormSelect value={this.state.guid} onChange={this.handleChangeGUID}>
                <option value="web">WEB & WORKER APP</option>
                <option value="client">CLIENT APP</option>
              </FormSelect>
            </InputGroup>



            <InputGroup className="mb-2">
              <InputGroupAddon type="prepend">
                <InputGroupText style={{width:"80px", color:"black"}}>Пункт</InputGroupText>
              </InputGroupAddon>
              <FormInput value={this.state.uid} onChange={this.handleChangeUID}/>
            </InputGroup>

            <InputGroup className="mb-2">
              <InputGroupAddon type="prepend">
                <InputGroupText style={{width:"80px", color:"black"}}>Название</InputGroupText>

              </InputGroupAddon>
              <FormTextarea value={this.state.title} onChange={this.handleChangeTITLE}/>
            </InputGroup>

          </Col>
        </Row>


        <br/>

        <Row>
          <Col>
            <Button
              pill
              theme="secondary"

              onClick={this.handleBtnApply}
            >Сохранить</Button>
          </Col>
        </Row>
        {/*  </ModalBody>*/}
        {/*</Modal>*/}
      </div>
    );
  }
}

function mapStateToProps(state){
  return {
    auth: state.auth,
    user: state.user,
  }
}

export default connect(mapStateToProps, null)(UserPairView)
