import React from "react";
import {Icon} from "semantic-ui-react"
import Modal from "react-bootstrap/Modal";
import AddNewView from "./NewView";
import {Button} from "shards-react";

export function AddNewModal(props) {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button onClick={handleShow} pill theme="secondary">Добавить пункт <Icon name="plus"/></Button>
      <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title style={{margin:"0px"}}><Icon name="file alternate" color="black"/> Добавить пункт</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddNewView closeModal={handleClose}/>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddNewModal;

